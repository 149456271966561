import {
  RawMaterial,
  Packing,
  Product,
  Configuration,
  PriceSheet,
  PriceSheetPrime,
  PriceSheetLatest,
  DatabaseDownload,
} from "./Api";
import {
  CreateFunction,
  ReadFunction,
  UpdateFunction,
  DeleteFunction,
} from "./ApiFunction";
import {
  successMessage,
  errorMessage,
} from "../MainPage/Functions/CommonFunctions";

const ErrorResponse = { status: 500, error: "Something Went Wrong" };

//Crud operation of rawMaterials
export const CreateNewRawMaterial = async ({
  VersionName,
  Materials,
  CloneVersionCreation,
}) => {
  if (!VersionName || !Materials) {
    console.log(VersionName, Materials, CloneVersionCreation);
    errorMessage("Version Name is required");
    return ErrorResponse;
  }
  return await CreateFunction(RawMaterial, {
    VersionName: VersionName,
    Materials: Materials,
    CloneVersionCreation: CloneVersionCreation,
  });
};
export const AddnewRawMaterialItem = async (ItemId, Materials) => {
  return await UpdateFunction(`${RawMaterial}/add`, ItemId, Materials);
};
export const EditRawMaterialItem = async (ItemId, Materials) => {
  return await UpdateFunction(`${RawMaterial}/edit`, ItemId, Materials);
};
export const DeleteRawMaterialItem = async (ItemId, MaterialIdReq) => {
  return await UpdateFunction(`${RawMaterial}/delete`, ItemId, {
    MaterialId: MaterialIdReq,
  });
};
// export const EditRawMaterialData = async (ItemId,updatedata) => {return await UpdateFunction(RawMaterial, ItemId , updatedata);};
export const ReadRawMaterialList = async () => {
  return await ReadFunction(RawMaterial);
};
export const ReadRawMaterialVersion = async () => {
  return await ReadFunction(`${RawMaterial}/version`);
};
export const ReadRawMaterialSelected = async () => {
  return await ReadFunction(`${RawMaterial}/selected`);
};
export const DeleteRawMaterial = async (id) => {
  return await DeleteFunction(RawMaterial, id);
};

//Crud operation of Packing
export const CreateNewPacking = async ({
  type,
  cost,
  litreIncluded,
  component,
  freightCharges,
}) => {
  if (!type || !cost || !litreIncluded || !component || !freightCharges) {
    errorMessage("Type, cost and litre are required");
    return ErrorResponse;
  }
  return await CreateFunction(Packing, {
    type: type,
    cost: cost,
    litreIncluded: litreIncluded,
    component: component,
    freightCharges: freightCharges,
  });
};
export const EditPackingData = async (ItemId, updatedata) => {
  return await UpdateFunction(Packing, ItemId, updatedata);
};
export const ReadPackingList = async () => {
  return await ReadFunction(Packing);
};
export const DeletePacking = async (id) => {
  return await DeleteFunction(Packing, id);
};

//Crud operation of Products
export const CreateNewProduct = async ({
  density,
  brand,
  type,
  name,
  productApi,
  saeGrade,
  availablePacking,
  rawMaterialComposition,
}) => {
  if (
    !density ||
    !brand ||
    !type ||
    !name ||
    !productApi ||
    !saeGrade ||
    !availablePacking.length > 0 ||
    !rawMaterialComposition.length > 0
  ) {
    errorMessage("All Values are required");
    return ErrorResponse;
  }
  return await CreateFunction(Product, {
    density: density,
    brand: brand,
    type: type,
    name: name,
    productApi: productApi,
    saeGrade: saeGrade,
    availablePacking: availablePacking,
    rawMaterialComposition: rawMaterialComposition,
  });
};
export const ReadProductList = async () => {
  return await ReadFunction(Product);
};
export const DeleteProduct = async (id) => {
  return await DeleteFunction(Product, id);
};
export const EditProductData = async (ItemId, updatedata) => {
  return await UpdateFunction(Product, ItemId, updatedata);
};

//Crud Operation of Configuration
export const ReadConfiguration = async () => {
  return await ReadFunction(Configuration);
};
export const ReadBrandList = async () => {
  return await ReadFunction(`${Configuration}/brands`);
};
export const ReadRoundOff = async () => {
  return await ReadFunction(`${Configuration}/roundoff`);
};
export const ReadUsersList = async () => {
  return await ReadFunction(`${Configuration}/users`);
};

export const HandleUserSignIn = async ({ username, password }) => {
  if (!username || !password) {
    errorMessage("username and password are required");
    return ErrorResponse;
  }
  return await CreateFunction(Configuration, {
    username: username,
    password: password,
  });
};
export const UpdateConfiguration = async (updatedata) => {
  return await UpdateFunction(Configuration, "", updatedata);
};

export const DownloadDatabaseBackup = async () => {
  return await ReadFunction(DatabaseDownload);
};

//Crud Operation of priceList
export const CreateNewPriceSheet = async (data) => {
  return await CreateFunction(PriceSheet, data);
};
export const ReadPriceSheet = async () => {
  return await ReadFunction(PriceSheet);
};
export const ReadPriceSheetData = async () => {
  return await ReadFunction(PriceSheetPrime);
};
export const ReadUserPriceSheet = async () => {
  return await ReadFunction(`${PriceSheet}/user-price`);
};
export const ReadPriceSheetLatest = async () => {
  return await ReadFunction(PriceSheetLatest);
};
export const DownloadPriceList = async (data) => {
  return await CreateFunction(`${PriceSheet}/download-xlsx`, data);
};
export const DownloadCustomPriceList = async (data) => {
  return await CreateFunction(`${PriceSheet}/download-custom-xlsx`, data);
};
