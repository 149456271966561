import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Dash1,
  Dash2,
  Dash3,
  Dash4,
  Dropdown,
  ClosesIcon,
  Filter,
  Search,
  search_whites,
  Excel,
} from "../EntryFile/imagePath";
import { Table } from "antd";
import FeatherIcon from "feather-icons-react";
import { ClassicSpinner } from "react-spinners-kit";
import { Link } from "react-router-dom";
import { ReportDownloadApi } from "../api/Api";
import {
  ReadPriceSheetData,
  // ReadPriceSheetLatest,
  ReadPackingList,
  ReadBrandList,
  DownloadCustomPriceList,
  ReadRoundOff,
} from "../api/IndividualFunction";
import Tabletop from "../EntryFile/tabletop";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [TableLoading, setTableLoading] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [ColumnData, setColumnData] = useState([]);
  const [BrandList, setBrandList] = useState([]);
  const [TotalChange, setTotalChange] = useState(0);
  const [DiscountPercentage, setDiscountPercentage] = useState(0);
  const [ProductType, setProductType] = useState("lubricant");
  const [ProductBrand, setProductBrand] = useState("");
  const [RoundOff, setRoundOff] = useState(2);
  const [LastUpdatedDate, setLastUpdatedDate] = useState("");

  const [searchText, setSearchText] = useState("");
  // const [filteredData, setFilteredData] = useState([]);
  const [FrieghtCharges, setFrieghtCharges] = useState(0);

  const [columns, setColumns] = useState([]);
  const [inputfilter, setInputfilter] = useState(false);

  async function fetchdata() {
    // setLoading(true);
    setTableLoading(true);
    // const responseData1 = await ReadPriceSheetLatest();
    // if (responseData1["status"] === 200) {
    //   setTotalChange(responseData1["data"]["totalChange"]);
    //   setLastUpdatedDate(responseData1["data"]["latestUpdatedFileName"]);
    // }
    const responseData = await ReadBrandList();
    if (responseData["status"] === 200) {
      setBrandList(responseData["data"]["Brands"]);
      if (
        responseData["data"] &&
        responseData["data"]["Brands"] &&
        responseData["data"]["Brands"].length > 0
      ) {
        setProductBrand(responseData["data"]["Brands"][0]);
      }
    }
    const responseData1 = await ReadRoundOff();
    if (responseData1["status"] === 200) {
      // console.log(responseData1["data"]["RoundOff"]);
      setRoundOff(responseData1["data"]["RoundOff"]);
    }
    // setLoading(false);
    setTableLoading(false);
    fetchProductdata();
  }

  const DownloadCustomFile = async () => {
    setLoading(true);
    let temp = {
      Brands: ProductBrand,
      ProductType: ProductType,
      DiscountPercentage: DiscountPercentage,
      FrieghtCharge: FrieghtCharges,
    };
    // console.log(temp);
    const responseData = await DownloadCustomPriceList(temp);
    // console.log(responseData);
    if (responseData["status"] === 200) {
      const link = document.createElement("a");
      link.href = ReportDownloadApi;
      link.download = "report.xlsx"; // Specify the file name

      // Append the link to the body and click it to trigger download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
    setLoading(false);
  };

  const CalculatePrice = (value, freight, singleFreight, discount) => {
    let responseValue = 0;
    if (value) {
      responseValue = value;
      if (discount && discount > 0) {
        responseValue = responseValue - (discount / 100) * responseValue;
      }
      if (freight && freight !== 0 && singleFreight && singleFreight !== 0) {
        responseValue = responseValue + freight / singleFreight;
      }
    }
    return parseFloat(responseValue.toFixed(RoundOff));
  };

  const fetchProductdata = async () => {
    setTableLoading(true);
    const responseData = await ReadPriceSheetData();
    if (responseData["status"] === 200) {
      setTableData(responseData["data"]);
    }
    const PackingData = await ReadPackingList();
    if (
      PackingData["status"] === 200 &&
      PackingData["data"] &&
      PackingData["data"][ProductType]
    ) {
      setColumnData(PackingData["data"]);
      let temp = [];
      for (let i = 0; i < PackingData["data"][ProductType].length; i++) {
        temp.push({
          title: PackingData["data"][ProductType][i]["type"],
          dataIndex: PackingData["data"][ProductType][i]["type"],
          render: (value) =>
            CalculatePrice(
              value,
              FrieghtCharges,
              PackingData["data"][ProductType][i]["freightCharges"],
              DiscountPercentage
            ),
        });
      }
      setColumns(temp);
    }
    setTableLoading(false);
  };
  useEffect(() => {
    fetchdata();
  }, []);

  const spinnerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const spinnerWrapperStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const ChangeTypeAndBrand = (val, data) => {
    if (val === "type") {
      // console.log(data, ColumnData);
      let temp = [];
      if (ColumnData[data]) {
        for (let i = 0; i < ColumnData[data].length; i++) {
          temp.push({
            title: ColumnData[data][i]["type"],
            dataIndex: ColumnData[data][i]["type"],
            render: (value) =>
              CalculatePrice(
                value,
                FrieghtCharges,
                ColumnData[data][i]["freightCharges"],
                DiscountPercentage
              ),
          });
        }
        setColumns(temp);
      } else {
        setColumns([]);
      }
      setProductType(data);
    } else if (val === "brand") {
      setProductBrand(data);
    } else if (val === "discount") {
      let temp = [];
      if (data >= 0 && data <= 100) {
        if (ColumnData[ProductType]) {
          for (let i = 0; i < ColumnData[ProductType].length; i++) {
            temp.push({
              title: ColumnData[ProductType][i]["type"],
              dataIndex: ColumnData[ProductType][i]["type"],
              render: (value) =>
                CalculatePrice(
                  value,
                  FrieghtCharges,
                  ColumnData[ProductType][i]["freightCharges"],
                  data
                ),
            });
          }
          // console.log(temp);
          setColumns(temp);
        }
        setDiscountPercentage(data);
      }
    } else if (val === "frieght") {
      let temp = [];
      if (data >= 0) {
        if (ColumnData[ProductType]) {
          for (let i = 0; i < ColumnData[ProductType].length; i++) {
            temp.push({
              title: ColumnData[ProductType][i]["type"],
              dataIndex: ColumnData[ProductType][i]["type"],
              render: (value) =>
                CalculatePrice(
                  value,
                  data,
                  ColumnData[ProductType][i]["freightCharges"],
                  DiscountPercentage
                ),
            });
          }
          setColumns(temp);
        }
        setFrieghtCharges(data);
      }
    }
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {loading ? (
            <div style={spinnerStyle}>
              <div style={spinnerWrapperStyle}>
                <ClassicSpinner size={50} color="black" loading={loading} />
              </div>
            </div>
          ) : null}
          <div>
            <div className="row">
              {/* <div className="col-lg-3 col-sm-6 col-12 ">
                <div className="dash-count">
                  <div className="dash-counts">
                    <h4>{TotalChange}</h4>
                    <h5>Total Changes</h5>
                  </div>
                  <div className="dash-imgs">
                    <FeatherIcon icon="git-pull-request" />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6 col-12 ">
                <div className="dash-count">
                  <div className="dash-counts">
                    <h4>{LastUpdatedDate}</h4>
                    <h5>Last Version</h5>
                  </div>
                  <div className="dash-imgs">
                    <FeatherIcon icon="file" />
                  </div>
                </div>
              </div> */}
            </div>
            {/* data tables */}
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header pb-0 d-flex justify-content-between align-items-center row">
                    <div className="pb-0 d-flex justify-content-between col-12">
                      <h4 className="card-title ">Price Sheet</h4>
                      <div className="graph-sets">
                        <a
                          data-tip="Excel"
                          className=" mb-0 p-1"
                          onClick={DownloadCustomFile}
                        >
                          <img src={Excel} alt="img" />
                        </a>
                      </div>
                    </div>

                    <hr />
                    <div className="col-12 row">
                      <div className="dropdown col-lg-4 col-sm-12">
                        <span>Search </span>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        {/* <div className="btn btn-searchset">
                            <img src={Search} alt="img" />
                          </div> */}
                      </div>

                      <div className="dropdown  col-lg-2 col-sm-6">
                        <span>Frieght Charge: </span>
                        <input
                          className="form-control"
                          type="number"
                          value={FrieghtCharges}
                          onChange={(e) =>
                            ChangeTypeAndBrand("frieght", e.target.value)
                          }
                        />
                      </div>

                      <div className="dropdown col-lg-2 col-sm-6">
                        <span>Discount:</span>
                        <input
                          className="form-control"
                          type="number"
                          value={DiscountPercentage}
                          onChange={(e) =>
                            ChangeTypeAndBrand("discount", e.target.value)
                          }
                        />
                      </div>
                      <div className="dropdown col-lg-2 col-sm-6">
                        <span>Brand </span>
                        <select
                          className="form-control"
                          value={ProductBrand}
                          onChange={(e) =>
                            ChangeTypeAndBrand("brand", e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Select a brand
                          </option>
                          {BrandList.map((data, index) => (
                            <option value={data} key={index}>
                              {data}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className=" col-lg-2 col-sm-6">
                        <span>Type </span>
                        <select
                          className="form-control"
                          value={ProductType}
                          onChange={(e) =>
                            ChangeTypeAndBrand("type", e.target.value)
                          }
                        >
                          <option value="lubricant">Lubricant</option>
                          <option value="grease">Grease</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* <div className="table-top">
                      <div className="search-set"> */}
                    {/* <div className="search-path">
                          <a
                            className={` btn ${
                              inputfilter ? "btn-filter setclose" : "btn-filter"
                            } `}
                            id="filter_search"
                            onClick={() => togglefilter(!inputfilter)}
                          >
                            <img src={Filter} alt="img" />
                            <span>
                              <img src={ClosesIcon} alt="img" />
                            </span>
                          </a>
                        </div>
                        */}
                    {/* <div className="search-input">
                          <input
                            className="form-control form-control-sm search-icon"
                            type="text"
                            placeholder="Search..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                          <div className="btn btn-searchset">
                            <img src={Search} alt="img" />
                          </div>
                        </div>
                      </div> */}
                    {/* <div className="wordset">
                        <ul> */}
                    {/* <ReactTooltip place="top" type="dark" effect="solid" /> */}
                    {/* <li>
                                      <a data-tip="Pdf">
                                        <img src={Pdf} alt="img" onClick={()=>GenerateReciept(data1.widget_name,(jsonData ? jsonData[data1.widget_name+'.json'] ? jsonData[data1.widget_name+'.json'].series ? jsonData[data1.widget_name+'.json'].series: []:[]:[]))}/>
                                      </a>
                                    </li> */}
                    {/* <li>
                            <a data-tip="Excel" onClick={DownloadCustomFile}>
                              <img src={Excel} alt="img" />
                            </a>
                          </li> */}
                    {/* <li>
                                      <a data-tip="Print">
                                        <img src={Printer} alt="img" />
                                      </a>
                                    </li> */}
                    {/* </ul>
                      </div>
                    </div> */}
                    {/* /Filter */}
                    {/* <div
                      className={`card mb-0 ${inputfilter ? "toggleCls" : ""}`}
                      id="filter_inputs"
                      style={{ display: inputfilter ? "block" : "none" }}
                    >
                      <div className="card-body pb-0">
                        <div className="row">
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="form-group">
                              <input type="text" placeholder="Enter Name" />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-6 col-12">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Enter Reference No"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* /Filter */}
                    <div className="table-responsive dataview">
                      <Table
                        columns={[
                          {
                            title: "Product Id",
                            dataIndex: "productId",
                            sorter: (a, b) =>
                              a.productId.length - b.productId.length,
                          },
                          {
                            title: "Product Name",
                            dataIndex: "",
                            sorter: (a, b) => {
                              // Compare strings alphabetically, taking numbers into account
                              return a.name.localeCompare(b.name);
                            },
                            render: (text, record) => (
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {`${record.name} ${record.productApi} ${record.saeGrade}`}
                              </div>
                            ),
                          },
                          ...columns,
                        ]}
                        dataSource={
                          TableData?.[ProductBrand]?.[ProductType]?.filter(
                            (item) =>
                              // Check if any of the fields include the search text
                              item.name
                                .toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.productApi
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.saeGrade
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.productid
                                ?.toLowerCase()
                                .includes(searchText.toLowerCase())
                          ) || []
                        }
                        pagination={false}
                        loading={{
                          indicator: (
                            <div>
                              <ClassicSpinner
                                size={50}
                                color="black"
                                loading={TableLoading}
                              />{" "}
                            </div>
                          ),
                          spinning: TableLoading,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
